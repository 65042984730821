import React from "react"
import loadable from "@loadable/component"
const MapComponent = loadable(() => import("../MapComponent"))
const CareerBlock = loadable(() => import("../CareerBlock"))
// const GetInTouch = loadable(() => import("../GetInTouch"))
const IndustryFocus = loadable(() => import("../IndustryFocus"))
const Associations = loadable(() => import("../Associations"))
const ExtendedContactForm = loadable(() =>
  import("../ExtendedContactForm/NewExtendedContactForm")
)
const HiringBlock = loadable(() => import("../HiringBlock"))
const GITEX_SECTION = loadable(() => import("../GitexSection/NewGitex"))
const CES_SECTION = loadable(() => import("../CES/CESSection"))
const CES_NEWSECTION = loadable(() => import("../CES/CESCalender"))
const CES_MAP = loadable(() => import("../CES/CESMap"))
const CES_SINGLE_PROFILE = loadable(() => import("../CES/CESSingleProfile"))
const CES_VIDEO_PLAYER = loadable(() => import("../CES/CESVideoPlayer"))
const GITEX_TWO_SECTION = loadable(() => import("../GitexTwoSection"))
const SimpleCalendly = loadable(() => import("../Forms/PopupCalandly"))
const GitexBtn = loadable(() => import("../GitexShortCode/GitexBtn"))
const GITEX_SECTION_NEW = loadable(() => import("../GitexSectionNew"))
const SINGLE_PROFILE = loadable(() => import("../SingleProfile"))
const AI_SECTION = loadable(() => import("../CESAI/AISliderSection"))
const AI_HERO_SECTION = loadable(() => import("../CESAI/AIHeroSection"))
const AI_SECTION_SILDER = loadable(() => import("../CESAI/AISectionSlider"))
const AI_SILDER = loadable(() => import("../CESAI/AIApproach"))
const AI_INDUSTRIES = loadable(() => import("../CESAI/AIIndustries"))
const AI_TECHSTACK = loadable(() => import("../CESAI/AITechStack"))
const CASE_STUDY_SLIDER = loadable(() => import("../CESAI/CaseStudy"))
const AI_PROFILESECTION = loadable(() => import("../CESAI/AIProfileSection"))
const AIML_SECTION = loadable(() => import("../AIML/AiMlFeature"))
const AIML_ENGINEER_SECTION = loadable(() => import("../AIML/AiEngineers"))
const AIML_INTEGRATION_SECTION = loadable(() =>
  import("../AIML/AiIntegrations")
)
const HIRE_AI_TECH_STACK = loadable(() => import("../AIML/HireAiTechStack"))
const AICHATBOT_SECTION = loadable(() => import("../AIML/AiChatbotDevFeature"))
const ML_SOLUTION_SECTION = loadable(() => import("../AIML/MlSolutions"))
const ML_TECH_STACK = loadable(() => import("../AIML/MlTechStack"))
const AI_BUSINESS_SLIDER = loadable(() => import("../AIML/AiBusinessApproach"))
const AIDEV_SERVICES_INDUSTRIES = loadable(() =>
  import("../AIML/AiDevServices")
)
const AI_RAG_INDUSTRIES = loadable(() => import("../AIML/AiRagIndustries"))
const LLM_SOLUTION_INDUSTRIES = loadable(() => import("../AIML/LlmSolutions"))
const AI_IN_MOBILE_INDUSTRIES = loadable(() => import("../AIML/AiInMobile"))
const AI_IN_SOFTWARE_INDUSTRIES = loadable(() =>
  import("../AIML/AiSoftwareDevlopment")
)
const AI_POWERED_INDUSTRIES = loadable(() =>
  import("../AIML/AiPoweredAutoIndustries")
)

const AI_BUSINESS_INDUSTRIES = loadable(() =>
  import("../AIML/AiBusinessIndustries")
)
const GENAI_INDUSTRIES = loadable(() => import("../AIML/GenAiDevIndustries"))
const AI_AGENT_SLIDER = loadable(() => import("../AIML/AIAgentsSlider"))
const GENAI_CONSULTING_INDUSTRIES = loadable(() =>
  import("../AIML/GenAiCounsultingIndustries")
)
const CHATGPT_INTEGRATION_INDUSTRIES = loadable(() =>
  import("../AIML/ChatGptIndustries")
)
const AI_IMGANDVIDEO_INDUSTRIES = loadable(() =>
  import("../AIML/AiImgVideoIndustries")
)
const ML_MODEL_ENGINEERING = loadable(() =>
  import("../AIML/MlModelEngineering")
)
const GENAI_TECH_STACK = loadable(() => import("../AIML/GenAiTechStack"))
const MLMODEL_TECH_STACK = loadable(() => import("../AIML/MlModelTechStack"))
const AI_AGENTDEV_INDUSTRIES = loadable(() =>
  import("../AIML/AiAgentDevelopmentIndustries")
)
const LLM_DEV_CARDS = loadable(() => import("../AIML/LlmDevCards"))
// const JOB_DETAIL_PAGE = loadable(() => import("../JobDetail"))

export const ShortCode = ({ attributes }) => {
  const { text } = attributes
  switch (text) {
    case "WMT_MAP":
      return <MapComponent />
    case "WMT_CAREER":
      return <CareerBlock />
    case "WMT_INDUSTRYFOCUS":
      return <IndustryFocus />
    case "WMT_ASSOCIATIONS":
      return <Associations />
    case "WMT_CONTACTFORM":
      return <ExtendedContactForm />
    case "WMT_FRESHERS":
      return <HiringBlock />
    case "WMT_CES_SECTION":
      return <CES_SECTION />
    case "WMT_CES_NEWSECTION":
      return <CES_NEWSECTION />
    case "WMT_CES_MAP":
      return <CES_MAP />
    case "WMT_CES_SINGLE_PROFILE":
      return <CES_SINGLE_PROFILE />
    case "WMT_CES_VIDEO_PLAYER":
      return <CES_VIDEO_PLAYER />
    case "WMT_AI_SECTION":
      return <AI_SECTION />
    case "WMT_AI_HEROSECTION":
      return <AI_HERO_SECTION />
    case "WMT_AI_SECTION_SILDER":
      return <AI_SECTION_SILDER />
    case "WMT_AI_SILDER":
      return <AI_SILDER />
    case "WMT_AI_PROFILE_SECTION":
      return <AI_PROFILESECTION />
    case "WMT_AI_INDUSTRIES":
      return <AI_INDUSTRIES />
    case "WMT_AI_TECHSTACK":
      return <AI_TECHSTACK />
    case "WMT_CASE_STUDY_SLIDER":
      return <CASE_STUDY_SLIDER />
    case "WMT_GITEX_SECTION":
      return <GITEX_SECTION />
    case "WMT_GITEX_SECTION_FORM":
      return <SimpleCalendly />
    case "WMT_GITEX_SECTION_BTN":
      return <GitexBtn />
    case "WMT_GITEX_SECTION_NEW":
      return <GITEX_SECTION_NEW />
    case "WMT_TWO_GITEX_SECTION":
      return <GITEX_TWO_SECTION />
    case "WMT_SINGLE_PROFILE":
      return <SINGLE_PROFILE />
    case "WMT_AIML_SECTION":
      return <AIML_SECTION />
    case "WMT_AIML_ENGINEER_SECTION":
      return <AIML_ENGINEER_SECTION />
    case "WMT_AIML_INTEGRATION_SECTION":
      return <AIML_INTEGRATION_SECTION />
    case "WMT_HIRE_AITECH_STACK":
      return <HIRE_AI_TECH_STACK />
    case "WMT_AICHATBOT_STACK":
      return <AICHATBOT_SECTION />
    case "WMT_MLSOLUTION_SECTION":
      return <ML_SOLUTION_SECTION />
    case "WMT_MLTECH_STACK":
      return <ML_TECH_STACK />
    case "WMT_AI_BUSINESS_SLIDER":
      return <AI_BUSINESS_SLIDER />
    case "WMT_AIDEV_SERVICES_INDUSTRIES":
      return <AIDEV_SERVICES_INDUSTRIES />
    case "WMT_AI_RAG_INDUSTRIES":
      return <AI_RAG_INDUSTRIES />
    case "WMT_LLM_SOLUTION_INDUSTRIES":
      return <LLM_SOLUTION_INDUSTRIES />
    case "WMT_AI_IN_MOBILE_INDUSTRIES":
      return <AI_IN_MOBILE_INDUSTRIES />
    case "WMT_AI_IN_SOFTWARE_INDUSTRIES":
      return <AI_IN_SOFTWARE_INDUSTRIES />
    case "WMT_AI_POWERED_INDUSTRIES":
      return <AI_POWERED_INDUSTRIES />
    case "WMT_AI_BUSINESS_INDUSTRIES":
      return <AI_BUSINESS_INDUSTRIES />
    case "WMT_GENAI_INDUSTRIES":
      return <GENAI_INDUSTRIES />
    case "WMT_AI_AGENT_SLIDER":
      return <AI_AGENT_SLIDER />
    case "WMT_GENAI_CONSULTING_INDUSTRIES":
      return <GENAI_CONSULTING_INDUSTRIES />
    case "WMT_CHATGPT_INTEGRATION_INDUSTRIES":
      return <CHATGPT_INTEGRATION_INDUSTRIES />
    case "WMT_AI_IMGANDVIDEO_INDUSTRIES":
      return <AI_IMGANDVIDEO_INDUSTRIES />
    case "WMT_ML_MODEL_ENGINEERING":
      return <ML_MODEL_ENGINEERING />
    case "WMT_GENAI_TECH_STACK":
      return <GENAI_TECH_STACK />
    case "WMT_MLMODEL_TECH_STACK":
      return <MLMODEL_TECH_STACK />
    case "WMT_AI_AGENTDEV_INDUSTRIES":
      return <AI_AGENTDEV_INDUSTRIES />
    case "WMT_LLM_DEV_CARDS":
      return <LLM_DEV_CARDS />
    // case "WMT_JOB_DETAIL_PAGE":
    //   return <JOB_DETAIL_PAGE />
    default:
      break
  }
}

export default ShortCode
